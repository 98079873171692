import React from "react";
import { AuthenticationResult, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { loginRequest, msalConfig } from "./authConfig";
import { CustomNavigationClient } from "./NavigationClient";
import { b2cPolicies } from "./b2cpolicies";
import { getToken, MSAL_LOCALSTORAGE_KEY } from "../services/auth";
import { hasUserProfile, SetApiToken, SetDefaultUserProfile } from "../services/cmsService";
import { navigate } from "gatsby";
import { showComposedMessage } from "../helpers/serviceHelper";

const msalInstance = new PublicClientApplication(msalConfig);
const postLogoutRedirectUrl = process.env.GATSBY_REDIRECT_URL;

const handleResponse = async (resp) => {
  if (resp && resp.account) {
    // This function has been implemented following the example at https://github.com/Azure-Samples/ms-identity-javascript-tutorial/tree/main/1-Authentication/2-sign-in-b2c
    if (resp.idTokenClaims['acr'] === b2cPolicies.names.forgotPassword) {
          window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");

          const logoutRequest = {
              account: msalInstance.getAccountByHomeId(resp.account.homeAccountId),
              postLogoutRedirectUri: postLogoutRedirectUrl
          };

          await msalInstance.loginRedirect();

    } else if (resp.idTokenClaims['acr'] === b2cPolicies.names.editProfile) {
        window.alert("Profile has been updated successfully.");
    } else {
      // sign-in as usual
      const key = resp.account.homeAccountId + '-' + resp.account.environment + '-' + resp.account.idTokenClaims.tid;
  
      localStorage.setItem(MSAL_LOCALSTORAGE_KEY, key);
      await handleAuthResponse(resp);
    }
  }  
}

msalInstance.handleRedirectPromise().then(handleResponse).catch((error) => {
  console.error(error);
  if (error?.errorMessage?.indexOf("AADB2C90118") > -1) {
    try {
      let redirectRequest: RedirectRequest = {
        authority : b2cPolicies.authorities.forgotPassword.authority,
        scopes: loginRequest.scopes
      }
      msalInstance.loginRedirect(redirectRequest);
    } catch(err) {
      console.log(err);
    }
  }
});

const handleAuthResponse = async (response: AuthenticationResult) => {
  const apiUser = await SetApiToken(response);
  const token = getToken();

  console.log("========> Got New user:", apiUser.isNewUser);
  const isUserProfileSet = await hasUserProfile(token, apiUser);

  if(!isUserProfileSet) {
    const defaultUserProfile = await SetDefaultUserProfile(response, token, apiUser);

    if(apiUser.isNewUser && defaultUserProfile.isSuccess) {
      navigate("/userprofile");
    }
  }
};

const AuthProvider = ({element}) => {
    /*
      The next 2 lines are optional. This is how you configure MSAL to take advantage of the
      router's navigate functions when MSAL redirects between pages in your app
    */
    const navigationClient = new CustomNavigationClient();
    msalInstance.setNavigationClient(navigationClient);

    return (
      <MsalProvider instance={msalInstance}>
        {element}
      </MsalProvider>
    );
}

export default AuthProvider;