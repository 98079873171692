/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/assets/css/main.scss";

import AuthProvider from "./src/msal/msal-provider";
export const wrapRootElement = AuthProvider;