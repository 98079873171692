// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-auth-microsoft-callback-tsx": () => import("./../../../src/pages/auth/microsoft/callback.tsx" /* webpackChunkName: "component---src-pages-auth-microsoft-callback-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-forums-tsx": () => import("./../../../src/pages/forums.tsx" /* webpackChunkName: "component---src-pages-forums-tsx" */),
  "component---src-pages-guidelines-tsx": () => import("./../../../src/pages/guidelines.tsx" /* webpackChunkName: "component---src-pages-guidelines-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manageorg-tsx": () => import("./../../../src/pages/manageorg.tsx" /* webpackChunkName: "component---src-pages-manageorg-tsx" */),
  "component---src-pages-map-tsx": () => import("./../../../src/pages/map.tsx" /* webpackChunkName: "component---src-pages-map-tsx" */),
  "component---src-pages-neworg-tsx": () => import("./../../../src/pages/neworg.tsx" /* webpackChunkName: "component---src-pages-neworg-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-search-guidelines-tsx": () => import("./../../../src/pages/search-guidelines.tsx" /* webpackChunkName: "component---src-pages-search-guidelines-tsx" */),
  "component---src-pages-seed-people-directory-tsx": () => import("./../../../src/pages/seed-people-directory.tsx" /* webpackChunkName: "component---src-pages-seed-people-directory-tsx" */),
  "component---src-pages-seed-people-leaflet-tsx": () => import("./../../../src/pages/SeedPeopleLeaflet.tsx" /* webpackChunkName: "component---src-pages-seed-people-leaflet-tsx" */),
  "component---src-pages-signout-tsx": () => import("./../../../src/pages/signout.tsx" /* webpackChunkName: "component---src-pages-signout-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-termsconditions-tsx": () => import("./../../../src/pages/termsconditions.tsx" /* webpackChunkName: "component---src-pages-termsconditions-tsx" */),
  "component---src-pages-training-2-tsx": () => import("./../../../src/pages/training2.tsx" /* webpackChunkName: "component---src-pages-training-2-tsx" */),
  "component---src-pages-training-tsx": () => import("./../../../src/pages/training.tsx" /* webpackChunkName: "component---src-pages-training-tsx" */),
  "component---src-pages-userprofile-tsx": () => import("./../../../src/pages/userprofile.tsx" /* webpackChunkName: "component---src-pages-userprofile-tsx" */),
  "component---src-templates-newsarticle-template-tsx": () => import("./../../../src/templates/newsarticle-template.tsx" /* webpackChunkName: "component---src-templates-newsarticle-template-tsx" */)
}

