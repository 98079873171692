import { BrowserAuthError, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { ClientAuthError } from "msal";
import { msalConfig } from '../msal/authConfig';
import * as qs from 'qs';
import apiConfig from './apiConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

export const isBrowser = () => typeof window !== "undefined";

export const MSAL_LOCALSTORAGE_KEY = 'msalLocalStorageKey';

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : {};

export const setUser = user =>
  isBrowser() && window.localStorage.setItem("gatsbyUser", JSON.stringify(user));

export const getUserProfile = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUserProfile")
    ? JSON.parse(window.localStorage.getItem("gatsbyUserProfile"))
    : {};

export const setUserProfile = user =>
  isBrowser() && window.localStorage.setItem("gatsbyUserProfile", JSON.stringify(user));
  
  
export const getToken = () =>
  isBrowser() && window.localStorage?.getItem("jwt");

export const setToken = (token: string) =>
    isBrowser() && window.localStorage.setItem("jwt", token);

export const apiLogout = () => {
  setToken("");
  setUser({})
}

export const handleRedirectCallback = (hashToken) => {

  let accessToken;
  if (!isBrowser) {
    return;
  }
  //console.log("Got token as:", hashToken);
  if (hashToken) {
    const qryParams = qs.parse(hashToken, { plainObjects: true });
    //console.log("Tokens: ", qryParams);
    //console.log("Access Token: ", qryParams.raw.access_token);
    accessToken = qryParams.raw?.access_token;
  }

  return accessToken;

};

export const persistLoggedInUser = (res: any) => {

  if(isBrowser()) {
    if(res && res.jwt) {
      console.log("Has JWT:", res.jwt);
      localStorage.setItem('jwt', res.jwt);
    }

    if(res && res.user) {
      // console.log("Has Username:", res.user.username);
      // localStorage.setItem('username', res.user.username);      
      console.log("User state:", JSON.stringify(res.user));
      // {"id":4,"username":"kaush1@florabankau.onmicrosoft.com","email":"kaush1@florabankau.onmicrosoft.com","provider":"microsoft","confirmed":true,"blocked":null,"role":{"id":1,"name":"Authenticated","description":"Default role given to authenticated user.","type":"authenticated"},"created_at":"2021-06-22T07:27:04.332Z","updated_at":"2021-06-22T07:27:04.354Z"}
      localStorage.setItem("gatsbyUser", JSON.stringify(res.user));
    }
  }
};

export const getMSALInfo = () => {
  if(isBrowser()) {
      const key = localStorage?.getItem(MSAL_LOCALSTORAGE_KEY);

    if (key) {
      const obj = localStorage.getItem(key);
      return obj ? JSON.parse(obj) : null;
    }
    else {
      return null;
    }
  }
};

export const getIsUserAuthenticated = () => {
  return getMSALInfo() ? true : false;
};

export const getUserName = () => {
  if (getIsUserAuthenticated()) {
    const claims = getMSALInfo()?.idTokenClaims;

    return claims.given_name + ' ' + claims.family_name;
  }
  else {
    return '';
  }
};

export const getTokenFromMSALInfo = () => {
  return getMSALInfo()?.clientInfo;
};

export const getMSALAccessToken = async () => {
  var accessToken = null as any;
  var tokenResponse = null as any;  
  console.log('msalInstance.getAllAccounts() ===', msalInstance.getAllAccounts())
  let signedInUser = msalInstance.getAllAccounts()[0];

  if(signedInUser) {

    var request = {
      account: signedInUser,
      scopes: apiConfig.b2cScopes
    } as any;
    
    try {
      tokenResponse = await msalInstance.acquireTokenSilent(request);
      accessToken = tokenResponse.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        tokenResponse = await msalInstance.acquireTokenRedirect(request).catch( err => console.log("InteractionRequiredAuthError on Redirect: ", err));
        console.log("Got Redirect TokenResponse as: ", tokenResponse);
        accessToken = tokenResponse.accessToken;
      }

      if( error instanceof BrowserAuthError) {
        // fallback to interaction when silent call fails
        tokenResponse = await msalInstance.acquireTokenRedirect(request).catch( err => console.log("BrowserAuthError on Redirect: ", err));
        console.log("Got Redirect TokenResponse as: ", tokenResponse);
        accessToken = tokenResponse.accessToken;
      }

      if( error instanceof ClientAuthError) {
        // fallback to interaction when silent call fails
        tokenResponse = await msalInstance.acquireTokenRedirect(request).catch( err => console.log("ClientAuthError on Redirect: ", err));
        console.log("Got Redirect TokenResponse as: ", tokenResponse);
        accessToken = tokenResponse.accessToken;
      }
    }
  }
  return accessToken;
};