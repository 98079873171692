import { AuthenticationResult } from '@azure/msal-browser';
import axios, { AxiosRequestConfig } from 'axios';
import { showComposedMessage } from '../helpers/serviceHelper';
import { IOrganisation } from '../models/cmsModels';
import { getToken, getUser, setToken, setUser, setUserProfile } from './auth';

const baseUrl = process.env.GATSBY_API_URL || 'http://localhost:1337';

export const cmsCollections = {
    Newsfeeds: 'newsfeeds',
    Guidelines: 'guidelines',
    Userprofiles: 'userprofiles',
    Organisations: 'organisations',
    GeoPostCodes: 'geo-postcodes',
    ContactEnquiries: 'contact-enquiries'
};

const getConfig = (accessToken: string): AxiosRequestConfig => (
  accessToken ?
    {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
  } : {}
);

const getFileConfig = (accessToken: string): AxiosRequestConfig => (
  accessToken ?
    {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
  } : {}
);

export const getRecord = async (accessToken: string | undefined, collectionName: string, recordId: string) : Promise<any> => {
  const config = getConfig(accessToken)

  const apiUrl = `${baseUrl}/${collectionName}/${recordId}`;

  try {
    const response = await axios.get(apiUrl, config);

    return response;
  }
  catch (ex: any) {
    showComposedMessage('getRecord', apiUrl, ex);
  }
};

export const getRecordsByParam = async(accessToken: string | undefined, collectionName: string, paramName, paramValue) : Promise<any> => {
  
  const config = getConfig(accessToken)
  
  const apiUrl = `${baseUrl}/${collectionName}/${paramName}/${paramValue}`;

  try {
    const response = await axios.get(apiUrl, config);

    return response.data;
  }
  catch (ex: any) {
    showComposedMessage('getRecordsByParam', apiUrl, ex);
  }
};

export const getAllRecords = async (accessToken: string | undefined, collectionName: string) : Promise<any> => {
    const config = getConfig(accessToken)

    const apiUrl = `${baseUrl}/${collectionName}`;

    try {
      const response = await axios.get(apiUrl, config);

      return response;
    }
    catch (ex: any) {
      showComposedMessage('getAllRecords', apiUrl, ex);
    }
};

export const getMapData = async (accessToken: string | undefined) : Promise<any> => {
  const config = getConfig(accessToken)

  const apiUrl = `${baseUrl}/${cmsCollections.Userprofiles}/map/-1`;

  try {
    const response = await axios.get(apiUrl, config);

    return response;
  }
  catch (ex: any) {
    showComposedMessage('getAllRecords', apiUrl, ex);
  }
};


export const UpdateUserRecord = async (accessToken: string | undefined, collectionName: string, payload: any, successFn?: Function) : Promise<any> => {

    const config = getConfig(accessToken);

    //const apiUrl = `${baseUrl}/content-manager/collection-types/application::${collectionName}.${collectionName}/${payload.id}`;
    const apiUrl = `${baseUrl}/${collectionName}/${payload.id}`;

    let response: any;

    try {
      
      response = await axios.put(apiUrl, payload, config);
      if (successFn) successFn();
      return response;
    }
    catch (ex: any) {
      showComposedMessage('UpdateUserRecord', apiUrl, ex);
    }
};

export const AddOrgRecord = async (accessToken: string | undefined, collectionName: string, payload: IOrganisation) : Promise<any> => {

  const config = getConfig(accessToken);
  
  // const apiUrl = `${baseUrl}/content-manager/collection-types/application::${collectionName}.${collectionName}`;
  const apiUrl = `${baseUrl}/${collectionName}`;
  let response: any;

  try {
    
    response = await axios.post(apiUrl, payload, config);

    return response;
  }
  catch (ex: any) {
    showComposedMessage('AddOrgRecord', apiUrl, ex);
  }
};

export const AddContactRecord = async (accessToken: string | undefined, collectionName: string, payload: any) : Promise<any> => {

  const config = getConfig(accessToken);
  
  // const apiUrl = `${baseUrl}/content-manager/collection-types/application::${collectionName}.${collectionName}`;
  const apiUrl = `${baseUrl}/${collectionName}`;
  let response: any;

  try {
    
    response = await axios.post(apiUrl, payload, config);

    return response;
  }
  catch (ex: any) {
    showComposedMessage('AddContactRecord', apiUrl, ex);
  }
};

export const SearchRecords = async (accessToken: string, collectionName: string, term: string, rowCount: number, pageNo: number = 1, filterTerm: any = null): Promise<any[]> => {

  const config = getConfig(accessToken);
  
  const apiUrl = `${baseUrl}/search/${collectionName}/${term}/${rowCount}/${pageNo}/${JSON.stringify(filterTerm)}`;
  let response: any;

  try {
    response = await axios.get(apiUrl, config);
    return response;
  }
  catch (ex: any) {
    showComposedMessage('search', apiUrl, ex);
  }
};

export const UploadPhoto = async (accessToken: string, filesList: any): Promise<any> => {
  const config = getFileConfig(accessToken);
  // console.log("jm: upload is called ")
  
  let formData = new FormData();
  formData.append("files", filesList.files[0]);

  const apiUrl = `${baseUrl}/upload`;
  let response: any;
    
  try {
    response = await axios.post(apiUrl,  formData, config);
    return response;
  }
  catch (ex: any) {
    showComposedMessage('upload photo', apiUrl, ex);
  }
};

export const GetLatLongFromPostcode = async (postcode: string) : Promise<any> => {
  
  const apiUrl = `${baseUrl}/geo-postcodes/postcode/${postcode}`;
  let response: any;

  try {
    
    response = await axios.get(apiUrl);

    return response;
  }
  catch (ex: any) {
    showComposedMessage('GetLatLongFromPostcode', apiUrl, ex);
  }
};

export const SetApiToken = async (response) => {

  let user = getUser();
  const token = getToken();

  //If User does not exist in local storage, get from Api
  if(!user || !user.email || !token || token.length == 0) {

    //console.log("ApiUser not logged in, retrieving user details...");
    const identifier = response.idTokenClaims["email"];
    const sub = response.idTokenClaims["sub"];  
    
    user = await GetUser(identifier, sub);
    //console.log("Got ApiUser details: ", apiResponse);
  }

  //Return user from local storage
  return user;
}


// This will get the user if they are registered, and if not, it will register them
const GetUser = async (identifier: string, sub: string) : Promise<any> => {

  let apiUser = {} as any;
  let apiUrl = `${baseUrl}/auth/local`;  
  let reqRegister = false;
  let apiResponse:any;

  const payload = { 
    'identifier' : identifier,
    'password': sub
  };

  try {
    apiResponse = await axios.post(apiUrl, payload);    
    if(apiResponse && apiResponse.data) {
      setToken(apiResponse.data.jwt);
      apiUser = apiResponse.data.user;
      apiUser.isNewUser = false;
      setUser(apiUser);
    }
  }
  catch (ex1) {    
    //console.log("Error occurred while user login: ", ex1.response);
    if(ex1.response.status == 400) {
      reqRegister = true;
      //console.log("User does not exists, registering a new user");
    }
  }

  try {
    if(reqRegister) {
      apiUrl += "/register";
      
      const registerUser = {
        "username": identifier,
        "email": identifier,
        "password": sub
      };

      apiResponse = await axios.post(apiUrl, registerUser);
      //console.log("User registration response: ", apiResponse);
  
      if(apiResponse && apiResponse.data) {
        setToken(apiResponse.data.jwt);
        apiUser = apiResponse.data.user;
        apiUser.isNewUser = true;
        setUser(apiUser);
      }
    }
  }
  catch(ex2) {
    //console.log("Link with api failed! Details: ", ex2);
  }

  return apiUser;
};

export const hasUserProfile = async (accessToken: string, user: any) => {
  let hasProfile = false;

  try {
    //const userProfile = await getRecord(accessToken, "userprofiles", userId);
    // console.log("jm: user.email ", user.email);
    const response = await getRecordsByParam(accessToken, "userprofiles", "email", user.email);
    // console.log("jm: response ", response);
    if(response && response?.userprofiles) {
      // console.log("Got UserProfiles as: ", response.userprofiles);
      const userProfiles = response.userprofiles;
      if(userProfiles && userProfiles.length > 0) {
        hasProfile = true;
        // console.log("jm: setUserProfile ", userProfiles[0]);
        setUserProfile(userProfiles[0]);
      }
    }
  }
  catch(err) {
    if(err.response == 404) {
      hasProfile = false;
    }
  }
  return hasProfile;
}

export const SetDefaultUserProfile = async (idToken: AuthenticationResult, accessToken: string, gatsbyUser: any) : Promise<any> => {

  let response = {
    isSuccess: false,
    results: null
  };
  const collectionName = "userprofiles";

  const payload = {
    "id": gatsbyUser?.id,
    "firstname" : idToken.idTokenClaims["given_name"],
    "lastname" : idToken.idTokenClaims["family_name"],
    "email" : gatsbyUser.email,
    "isUserVisible" : true,
    // set default photo (as normal avatar)
    "userPhoto": process.env.GATSBY_DEFAULT_AVATAR
  };

  const config = getConfig(accessToken);

  //const apiUrl = `${baseUrl}/content-manager/collection-types/application::${collectionName}.${collectionName}`;
  const apiUrl = `${baseUrl}/${collectionName}`;

  try {
    const isUserProfileSet = await hasUserProfile(accessToken, gatsbyUser);
    // console.log("jm: hasuserprofile ", isUserProfileSet);
    if(!isUserProfileSet) {
      response.results = await axios.post(apiUrl, payload, config);
    }
    response.isSuccess = true;
  }
  catch (ex: any) {
    showComposedMessage('AddUserProfileRecord', apiUrl, ex);
  }

  return response;
};