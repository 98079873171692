import { AxiosRequestConfig } from "axios";
import cryptoRandomString  from "crypto-random-string";

export const getConfig = (accessToken: string) => {
    const config : AxiosRequestConfig = {
        headers: { 
          'Authorization': `Bearer ${accessToken}`
        }
    };

    return config;
}

export const getUrlParamWithValue = (paramName: string, paramValue: any, isFirstParam: boolean = false): string => {
    return paramValue ? `${isFirstParam ? '?' : '&'}${paramName}=${paramValue}` : '';
};

export const showComposedMessage = (methodName: string, url: string, error: any) => {
    const message = `[${methodName}]: ${error.message} - ${url}`;
    console.log(message);
    //alert(message);
}

export const generateRandomString = (length) => {
    let randomString = "";
    
    let interval = length%10;

    for(let i = 1; i <= length/10; i++) {
        randomString += Math.random().toString(16).substr(2, 10);
    }

    if(interval > 0 && interval < 10) {        
        randomString += Math.random().toString(16).substr(2, interval);
    }

    return randomString;
}